@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
.about-list{
    background-color: white;
}

main{
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
}

.about-page{
    max-width: 210px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
}

.about-page a{
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
}

.about-page a:hover{
    opacity: 0.5;
}

.our-story{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.our-story-text h2{
    font-size: 54px;
    font-weight: 600;
}

.our-story-p1, .our-story-p2{
    width: 400px;
    font-size: 16px;
    font-weight: 400;
}

.our-story-p1{
    margin:40px 0 24px;
}

.about-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 140px 0;
}

.content{
    width: 270px;
    height: 230px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.30);
    padding: 30px 10px;
    text-align: center;
}

.content h2{
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0 5px;
}

.content p{
    font-size: 16px;
    font-weight: 400;
}

.content:hover{
    background-color: #DB4444;
    color: white;
    transition: 0.2s;
}

.about-user{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user h2{
    font-size: 32px;
    font-weight: 500;
    margin: 32px 0 5px;
}

.user p{
    font-size: 16px;
    font-weight: 400;
}

.iconca-user{
    display: flex;
    column-gap: 16px;
    margin-top: 16px;
    font-size: 18px;
}

.service{
    max-width: 950px;
    display: flex;
    justify-content: space-between;
    margin: 196px auto 140px;
    text-align: center;
}

.service-item h5{
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
}