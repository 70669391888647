@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
.Login{
    background-color: white;
}
.acount-item{
    max-width: 1200px;
    display: flex;
    column-gap: 130px;
    align-items: center;
    margin: 0px auto 140px;
    font-family: 'Poppins', sans-serif;
}

.acount-img{
    width: 800px;
    height: 600px;
}

.creat-account h4{
    font-size: 36px;
    color: #000;
    font-weight: 500;
}

.p-text{
    font-size: 16px;
    font-weight: 400;
    margin: 24px 0 30px;
}

.user-box input{
    width: 370px;
    height: 32px;
    border: none;
    opacity: 0.5;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 2px solid black;
    margin-bottom: 30px;
    outline: none;
}

.user-box input.error{
    border-bottom: 2px solid red;
}

.user-box input.valid{
    border-bottom: 2px solid green;
}

.login{
    max-width: 370px;
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-btn{
    width: 143px;
    height: 56px;
    border-radius: 4px;
    background: var(--Button2, #DB4444);
    font-size: 16px;
    font-weight: 500;
    color: white;
    border: none;
}

.login-btn:hover{
    background-color: #000;
    color: white;
    transition: 0.2s;
}

.signup-btn:hover{
    background-color: #000;
    color: white;
    transition: 0.3s;
}

.login Link{
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
    color: #DB4444;
    text-decoration: none;
}