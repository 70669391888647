@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
.contact{
    background-color: white;
}

main{
    max-width: 1200px;
    margin: 60px auto 100px;
    font-family: 'Poppins', sans-serif;
}

.contact-page{
    max-width: 250px;
    display: flex;
    column-gap: 12px;
}

.contact-page a{
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
}

.contact-page a:hover{
    transition: 0.2s;
    opacity: 0.5;
}

.contact-container{
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.contact-about{
    width: 340px;
    height: 457px;
    padding: 40px 35px 51px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.05);
}

.about{
    display: flex;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 24px;
}

.fa-phone, .fa-envelope{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #DB4444;
    color: white;
    text-align: center;
    padding-top: 12px;
    font-size: 18px;
}

.about p{
    font-size: 16px;
    font-weight: 700;
}

.contact-p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
}

.contact-hr{
    margin: 32px 0 32px;
}

.main-form{
    width: 800px;
    height: 457px;
    border-radius: 4px;
    background:  #FFF;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.05);
    padding: 40px 31px;
}

.main-form input{
    width: 235px;
    height: 50px;
    color:black;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.5;
    padding-left: 16px;
    margin-left: 8px;
}

.main-form textarea{
    width: 730px;
    height: 207px;
    color:black;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.5;
    margin: 32px 0 0 7px;
    padding: 13px 0 0 16px;
}

.main-form button{
    width: 215px;
    height: 56px;
    border-radius: 4px;
    background: var(--Button2, #DB4444);
    font-size: 16px;
    font-weight: 500;
    border: none;
    color: white;
    margin:30px 0 0 520px;
}

.main-form button:hover{
    color: #DB4444;
    background-color: white;
    border: 3px solid #DB4444;
    transition: 0.2s;
}